import "./contents.css";
import { lazy } from "react";
import pouria from "./photo/PouriaIssapour.jpg";
const Experience = lazy(() => import("./Experience"));
const Jobs = lazy(() => import("./Jobs"));

const Contents = () => {
  return (
    <div className="contents--container">
      <h2 id="about" className="contents--heading">
        About Me.
      </h2>
      <div className="contex--container contex--container__about">
        <div className="contents--contex">
          <p>
            Hello, my name's Pouria and I like making stuff for the internet. I
            got into web development in 2018 after trying to modify my cousin's
            website, and learnt a lot about HTML & CSS.
          </p>
          <p>
            Nowadays, I work for Upstatement and create products for different
            clients. I've had previous jobs at an developing company, a big
            company with great clients. I focus on building accessible and
            inclusive digital experiences.
          </p>

          <p>Here are a few technologies I’ve been working with recently:</p>
          <ul className="contents--contex">
            <li>C#</li>
            <li>JavaScript (ES6+)</li>
            <li>HTML5</li>
            <li>CSS</li>
          </ul>
        </div>
        <img src={pouria} alt="Pouria Issapour" className="contex--image" />
      </div>
      <h2 id="experience" className="contents--heading">
        My Experience
      </h2>
      <div className="contents--contex ">
        {" "}
        <Experience />
      </div>
      <h2 id="work" className="contents--heading">
        What I Did
      </h2>
      <div className="contents--contex contents--contex__jobs ">
        {" "}
        <Jobs />
      </div>
      <h2 id="contact" className="contents--heading">
        Get In Touch
      </h2>
      <p className="contents--contex contents--contex__center ">
        I am currently seeking new job opportunities and would welcome any
        relevant job openings that match my skills and experience. Please feel
        free to{" "}
        <a
          href="mailto:pouria.issapour@gmail.com?subject=Job opportunity"
          className="contents--contex__hirelink">
          reach
        </a>{" "}
        out if you have any leads or if you would like to connect and discuss
        potential job opportunities.
      </p>
    </div>
  );
};

export default Contents;
