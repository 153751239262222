import { useSpring, animated } from "react-spring";
import "./Main.css";

const Main = () => {
  const mainAnimation = useSpring({
    from: { opacity: 0, transform: "translateY(100px)" },
    to: { opacity: 1, transform: "translateY(0px)" },
    delay: 700,
  });
  return (
    <div className="main">
      <div className="main--left">
        <span className="main--left__btn1">pouria.issapour@gmail.com</span>
        <span className="main--verticalline main--left__btn2"></span>
      </div>
      <animated.header style={mainAnimation}>
        <div className="main--center">
          <div className="main--center__intro">
            <h5 className="main--heading__medium">Hi, my name is</h5>
            <h2 className="main--heading__big">Pouria Issapour</h2>
            <h1 className="main--heading__big">I'm Full stack Developer</h1>
            <p className="main--info">
              Experienced Developer in C#, JavaScript, HTML and CSS, with
              experience in API and responsive design. Currently expanding
              skills in React. Collaborative team player with more than 2 years
              of website and web application development and maintenance
              experience. Worked alongside senior developers, ensuring reliable
              contributions. Started with microcontroller programming C++ in
              2013, transitioning Full Stack Developing in 2020.
            </p>
            <a
              id="hirebtn"
              href="mailto:pouria.issapour@gmail.com?subject=Job opportunity"
              className="main--center__button">
              Hire Me
            </a>
          </div>
          <div className="main--center__about"></div>
          <div className="main--center__experience"></div>
          <div className="main--center__work"></div>
          <div className="main--center__contact"></div>
        </div>
      </animated.header>

      <div className="main--right">
        <a
          href="https://www.linkedin.com/in/pouria-issapour/"
          target="_blank"
          rel="noopener noreferrer"
          className="fa-brands fa-linkedin fa-xl main--right__link main--right__link1"
          aria-label="Pouria Issapours Linkedine link">
          {" "}
        </a>
        <a
          href="https://github.com/pouria87"
          target="_blank"
          rel="noopener noreferrer"
          className="fa-brands fa-github fa-xl main--right__link main--right__link2"
          aria-label="Pouria Issapours Github link">
          {" "}
        </a>
        <a
          href="https://www.facebook.com/pouria.issapour/"
          target="_blank"
          rel="noopener noreferrer"
          className="fa-brands fa-facebook fa-xl main--right__link main--right__link3"
          aria-label="Pouria Issapours Facebook link">
          {" "}
        </a>
        <a
          href="https://www.instagram.com/pouria.issapour"
          target="_blank"
          rel="noopener noreferrer"
          className=" fa-brands fa-instagram fa-xl main--right__link main--right__link4"
          aria-label="Pouria Issapours Insagram link">
          {" "}
        </a>
        <span className="main--verticalline  main--right__link main--right__link5">
          {" "}
        </span>
      </div>
    </div>
  );
};

export default Main;
