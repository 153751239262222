import React from "react";

function NotFoundPage() {
  return (
    <div style={{ textAlign: "center" }}>
      <h1 style={{ margin: "30px" }}>404 - Page not found</h1>
      <h2>
        <a
          style={{ textDecoration: "underline" }}
          href="https://pouria.issapour.com/ "
        >
          home
        </a>
      </h2>
    </div>
  );
}

export default NotFoundPage;
