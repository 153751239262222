import "./style.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import HeaderSection from "./Header";
import MainSection from "./Main";
import "@fortawesome/fontawesome-free/css/all.css";
import ContentsSection from "./Contents";
import NotFoundPage from "./NotFoundPage";

function App() {
  useEffect(() => {
    document.title = "MY PortFolio";
  }, []);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <>
                {" "}
                <HeaderSection />
                <MainSection />
                <ContentsSection />
                <footer></footer>
              </>
            }
          />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
