import { useSpring, animated } from "react-spring";
import { useState } from "react";
import "./Header.css";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const headerAnimation = useSpring({
    from: { opacity: 0, transform: "translateY(-100%)" },
    to: { opacity: 1, transform: "translateY(0%)" },
    delay: 400,
  });

  return (
    <animated.header style={headerAnimation}>
      <header className="header">
        <span className="header--logo fa-brands fa-react fa-3x" />

        <div className="header--button__toggle">
          <button
            className="header--button__toggle fa fa-bars fa-2x"
            onClick={toggleMenu}
            aria-label="Toggle menu"></button>
        </div>
        <div className={`header--nav ${isMenuOpen ? "open" : "close"}`}>
          <a href="#about" className="header--nav__link">
            About
          </a>
          <a href="#experience" className="header--nav__link">
            Experience
          </a>
          <a href="#work" className="header--nav__link">
            Work
          </a>
          <a href="#contact" className="header--nav__link">
            Contact me
          </a>
          <a
            href="https://pouria.issapour.com/resume/2023 08 01 - POURIA ISSAPOUR - Resume.docx"
            className="header--nav__resume"
            rel="noopener noreferrer"
            download>
            Resume
          </a>
        </div>
      </header>
    </animated.header>
  );
};

export default Header;
